<template>
  <div class="btn-driver-main">
    <transition name="screen">
      <div v-if="activeRatingModal == 'setting'" class="article-setting">
        <h2>Article Details</h2>
        <div class="article-selet">
          <h4>Topics and Subtopics</h4>
          <form action="">
            <input
              type="search"
              id="gsearch"
              name="gsearch"
              placeholder="Select Topics and sub topics"
            />
          </form>
        </div>
        <div class="article-selet">
          <h4>Location</h4>
          <form action="">
            <input
              type="search"
              id="gsearch"
              name="gsearch"
              placeholder="Search Location"
            />
          </form>
        </div>
        <div class="article-selet">
          <h4>Keywords</h4>
          <form action="">
            <input
              type="search"
              id="gsearch"
              name="gsearch"
              placeholder="Add Keywords"
            />
          </form>
        </div>
      </div>
    </transition>
    <transition name="screen">
      <div v-if="activeRatingModal == 'chat'" class="article-chats">
        <div class="chats-header">
          <div class="chats-header__title">
            <p>Collaboration Chat</p>
            <i class="icon icon-cross" @click="activeRatingModal = null"></i>
          </div>
          <p>You, Alejandro Guitez</p>
        </div>
        <div class="chats-body">
          <div class="users-message">
            <div class="user-img">
              <img
                src="../../assets/images/profile-pic.webp"
                alt="User Picture"
              />
            </div>
            <div class="message">
              <p>What is Lorem Ipsum?</p>
            </div>
          </div>
          <div class="users-message you">
            <div class="message">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div class="user-img">
              <img
                src="../../assets/images/profile-pic.webp"
                alt="User Picture"
              />
            </div>
          </div>
          <div class="users-message">
            <div class="user-img">
              <img
                src="../../assets/images/profile-pic.webp"
                alt="User Picture"
              />
            </div>
            <div class="message">
              <p>What is Lorem Ipsum?</p>
            </div>
          </div>
          <div class="users-message you">
            <div class="message">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
            </div>
            <div class="user-img">
              <img
                src="../../assets/images/profile-pic.webp"
                alt="User Picture"
              />
            </div>
          </div>
        </div>
        <div class="chats-footer">
          <div class="user-img">
            <img
              src="../../assets/images/profile-pic.webp"
              alt="User Picture"
            />
          </div>
          <form action="">
            <input
              type="text"
              id="text"
              name="text"
              placeholder="Type your message here."
            />
          </form>
          <i class="icon icon-send"></i>
        </div>
      </div>
    </transition>
    <transition name="message">
      <div class="btn btn-chat" @click="onClick('chat')" v-if="showButtons">
        <i class="icon icon-chat_filled"></i>
      </div>
    </transition>
    <transition name="setting">
      <div
        class="btn btn-setting"
        @click="onBtnClick('setting')"
        v-if="showButtons"
      >
        <i class="icon icon-settings"></i>
      </div>
    </transition>
    <div
      class="btn add-btn"
      :class="{ addBtnActive: showButtons }"
      @click="showOptions()"
      ref="open-dialouge"
    >
      <i class="icon icon-add_plus"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeRatingModal: null,
      showButtons: false,
      markedImpact: false,
    };
  },
  methods: {
    ToggleImpactMark() {
      this.markedImpact = !this.markedImpact;
    },
    onClick(activeRatingModal) {
      if (this.activeRatingModal != activeRatingModal) {
        this.activeRatingModal = activeRatingModal;
      } else {
        this.activeRatingModal = null;
      }
    },
    showOptions() {
      this.showButtons = !this.showButtons;
      this.activeRatingModal = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@include reset-css;

@keyframes setting-animate {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes message-animate {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.setting-enter-active {
  animation: setting-animate 0.2s ease-in;
}

.setting-leave-active {
  animation: setting-animate 0.2s ease-in reverse;
}

.message-enter-active {
  animation: message-animate 0.3s ease-in;
}

.message-leave-active {
  animation: message-animate 0.3s ease-in reverse;
}

.btn-driver-main {
  position: relative;
  .screen-enter-from,
  .screen-leave-to {
    transform: translate(50%, 50%) scale(0);
  }
  .screen-enter-active {
    transition: transform 0.2s ease-out;
  }
  .screen-enter-to,
  .screen-leave-from {
    transform: translate(0, 0) scale(1);
  }
  .article-chats {
    position: absolute;
    box-shadow: 0px 3px 44px #00000030;
    right: 7rem;
    bottom: 0;
    width: 40.2rem;
    .chats-header {
      background-color: $color-primary;
      padding: 1.4rem 2.4rem;
      color: $color-white;
      p {
        font-size: 1.3rem;
      }
      &__title {
        display: flex;
        justify-content: space-between;
        font-size: 1.9rem;
        font-family: $font-primary-semibold;
      }
      .icon {
        font-size: 2.7rem;
        cursor: pointer;
      }
    }
  }
  .chats-body {
    height: 35.6rem;
    background-color: $color-white;
    overflow-y: auto;
    .users-message {
      display: flex;
      align-items: flex-end;
      margin: 2rem;
      .user-img {
        width: 3rem;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .message {
        margin-left: 0.7rem;
        padding: 0.8rem 1.4rem;
        border-radius: 2rem;
        background-color: #8080801f;
        max-width: 60%;
        width: fit-content;
        p {
          font-size: 1.2rem;
          font-family: $font-secondary;
        }
      }
    }
    .you {
      justify-content: flex-end;
      .user-img {
        width: 2rem;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .message {
        margin-left: 0;
        margin-right: 0.7rem !important;
      }
    }
  }
  .chats-body::-webkit-scrollbar-track {
    background-color: #ffffff3f;
  }

  .chats-body::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 3rem;
  }
  .chats-body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 3rem;
  }
  .chats-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 2.4rem;
    background-color: $color-light-grey-2;
    .user-img {
      width: 3.4rem;
      height: 3.4rem;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    form {
      input {
        width: 100%;
        outline: none;
        width: 27rem;
        padding: 0.6rem 0.9rem;
        border: 1px solid #7070703b;
        font-size: 1.4rem;
      }
      input::placeholder {
        font-size: 1rem;
        color: #373737;
      }
    }
    .icon {
      font-size: 3rem;
      color: $color-primary;
      cursor: pointer;
      input {
        width: 100%;
        outline: none;
        border: none;
        padding: 1.5rem 2rem;
        font-family: $font-secondary;
        font-size: 1.4rem;
      }
    }
  }
}
.article-setting {
  position: absolute;
  background-color: #f6f8ff;
  .btn:hover ~ .child-btns {
    display: block;
  }
  box-shadow: 0px 3px 44px #00000030;
  padding: 2.5rem 2.4rem;
  right: 7rem;
  bottom: 0;
  width: 40.2rem;
  h2 {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
    margin-bottom: 2rem;
  }
  .article-selet {
    h4 {
      font-size: 1.4rem;
      font-family: $font-secondary-bold;
    }
    form {
      margin: 1.7rem 0;
      border: none;
      padding: 1.5rem 2rem;
      font-family: $font-secondary;
      font-size: 1.4rem;
    }
    input {
      width: 100%;
      outline: none;
      border: none;
      padding: 1.5rem 2rem;
      font-family: $font-secondary;
      font-size: 1.4rem;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }
}

.btn {
  background-color: $color-primary;
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon {
    color: $color-white;
    font-size: 2.1rem;
    cursor: pointer;
    transition: 0.2 transform;
    transform: rotate(0deg);
  }
}
.btn-setting {
  margin: 1.2rem 0;
}
.btn-chat {
  .icon::after {
    position: absolute;
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: red;
    border-radius: 30px;
    left: 1.2rem;
    top: 1.2rem;
  }
}
.child-btns {
  display: flex;
  flex-direction: column;
  display: none;
}
.addBtnActive {
  background-color: black;
  .icon {
    transform: rotate(45deg);
    transition: 0.2s transform;
  }
}

.active {
  display: block;
}
</style>
