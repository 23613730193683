<template>
  <div class="workspase-navbar">
    <div class="workspase-navbar__left">
      <div class="logo-box">
        <img src="../../assets/images/Amblem2@2x.webp" alt="GLocal Logo" />
        <h2>Glocal Workspace</h2>
      </div>
      <div class="navigation">
        <div :class="{ active: isActive('write'), navigation__item: true }">
          <p @click="clickNav('write')">Write</p>
        </div>
        <div
          :class="{ active: isActive('collaboration'), navigation__item: true }"
        >
          <p @click="clickNav('collaboration')">Collaboration Corner</p>
        </div>
        <div
          :class="{
            active: isActive('article-preview'),
            navigation__item: true,
          }"
        >
          <p @click="clickNav('article-preview')">Preview Article</p>
        </div>
      </div>
    </div>
    <div class="workspase-navbar__right">
      <div class="weather-icon">
        <i class="icon icon-brightness weather-icon__icon"></i>
        <p class="weather-icon__para">91.4°F</p>
      </div>
      <i class="icon icon-bookmark"></i>
      <i class="icon icon-notification_bell active"></i>
      <a-dropdown :trigger="['click']" placement="bottomRight">
        <a class="ant-dropdown-link">
          <i class="icon icon-account_circle notification__icon"></i>
        </a>
        <template #overlay>
          <accounts-dropdown></accounts-dropdown>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
import AccountsDropdown from "../BaseComponents/AccountsDropdown.vue";
export default {
  components: {
    AccountsDropdown,
  },
  data() {
    return {
      selectedTab: null,
    };
  },
  mounted() {
    this.selectedTab = this.$route.name;
  },
  methods: {
    clickNav(tab) {
      this.selectedTab = tab;
      this.$router.push("/workspace/" + tab);
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
  },
};
</script>

<style lang="scss" scoped>
@include reset-css;
.workspase-navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 2.4rem;
  @include respond(tab-port) {
    padding: 0;
  }
  &__left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 4.2rem;
        margin-right: 1.7rem;
      }
      h2 {
        font-size: 2.7rem;
        color: #373737;
        font-family: $font-secondary-bold;
        font-weight: 900;
        margin: 0;
        @include respond(laptop-small) {
          font-size: 2rem;
        }
        @include respond(tab-port) {
          display: none;
        }
      }
    }
    .navigation {
      display: flex;
      align-items: center;
      &__item {
        cursor: pointer;
        font-size: 1.8rem;
        color: #191919;
        margin: 0rem 2.4rem;
        font-family: $font-secondary;
        position: relative;
        opacity: 0.8;
        @include respond(laptop-small) {
          font-size: 1.7rem;
          margin: 0rem 1.8rem;
        }
        @include respond(tab-port) {
          font-size: 1.4rem;
        }
      }
      .active {
        font-family: $font-secondary-bold;
        &::after {
          content: "";
          width: 2.5rem;
          height: 0.4rem;
          background-color: $color-black;
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          opacity: 1;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .weather-icon {
      display: flex;
      align-items: center;
      position: relative;
      color: #000;
      margin-right: 2.4rem;
      border-right: 0.22rem solid $color-black;
      border-left: 0.22rem solid $color-black;
      padding: 0 1rem;
      @include respond(phone-x-small) {
        display: none;
      }

      &__icon {
        color: $color-black;
        font-size: 2.5rem;
        font-family: $font-secondary-bold;
        position: relative;
        margin-right: 0.7rem;
        margin-left: 0 !important;
      }
      &__para {
        color: $color-black;
        font-size: 1.2rem;
        font-family: $font-primary-bold;
        font-weight: 400;
        margin: 0;
      }
    }
    .icon {
      margin: 0rem 1.5rem;
      font-size: 2.3rem;
      position: relative;
      cursor: pointer;
      color: black;
      @include respond(tab-port) {
        font-size: 1.8rem;
        margin: 0 1rem;
      }
    }
    .active {
      &::after {
        content: "";
        background-color: red;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 100%;
        position: absolute;
        right: 0.2rem;
      }
    }
  }
}
</style>
