<template>
  <a-modal width="1000px">
    <div class="request-collaboration">
      <div class="select-connection-groups">
        <a-select
          default-value="Select Connection groups"
          style="width: 100%"
          :options="groupOptions"
          @change="selectGroup"
        >
        </a-select>
      </div>
      <form action="" class="search">
        <i class="icon icon-search"></i>
        <input type="text" placeholder="Search for authors here.." />
      </form>
      <h2 class="list-title">Authors in your list</h2>
      <div v-if="selectedAudience==='all'" class="user-list">
        <div v-if="loadingConnections"  class="loader">
          <the-loader></the-loader>
        </div>
        <p v-else-if="friends.length==0" class="message">No friends in your list</p>
        <div v-else v-for="friend in friends" :key="friend.id" class="user">
          <div class="user__left">
            <div class="user-img">
              <img src="../../assets/images/profile-pic.webp" alt="User" />
            </div>
            <div class="user-name">
              <h4>{{friend.profile.full_name}}</h4>
              <p>New York, United States</p>
            </div>
          </div>
          <div class="user__right">
              <a-select
                ref="select"
                v-model:value="selectedRole"
                :options="collaboratorRoles"
                style="width: max-content"
                @change="selectRole(friend.email)"
              >
              </a-select>
            <a-button class="user-request" @click="requestCollaboration(friend.email, friend.profile.full_name, friend.id)">Request Collaboration</a-button>
          </div>
        </div>
      </div>
      <div v-else v-for="(group, index) in connectionsInGroup" :key="group" >
        <div v-if="selectedAudience == index" class="user-list">
          <div v-if="loadingConnections"  class="loader">
            <the-loader></the-loader>
          </div>
          <p v-else-if="group.length==0" class="message">No Connections in this Group</p>
          <div v-else v-for="conn in connectionsInGroup[index]" :key="conn.id" class="user">
            <div class="user__left">
              <div class="user-img">
                <img src="../../assets/images/Profile-pic@2x.png" alt="User" />
              </div>
              <div class="user-name">
                <h4>{{conn.full_name}}</h4>
                <p>New York, United States</p>
              </div>
            </div>
            <div class="user__right">
              <a-select
                ref="select"
                v-model:value="selectedRole"
                :options="collaboratorRoles"
                style="width: max-content"
                @change="selectRole(conn.email)"
              >
              </a-select>
              <a-button class="user-request" @click="requestCollaboration(conn.email, conn.full_name, conn.id)">Request Collaboration</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <message-toaster 
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
    >
    </message-toaster>
  <a-modal
      width="45rem" :visible="showRequestModal" :footer="null"
    >
      <div class="modal-content">
        <h2 class="publish-heading">Sending Request...</h2>
        <p class="publish-text">
          Please wait
        </p>
      </div>
    </a-modal>
  </a-modal>
</template>
<script>
import MessageToaster from '../BaseComponents/MessageToaster.vue';
import TheLoader from "../BaseComponents/TheLoader.vue";
export default {
  components:{
    MessageToaster,
    TheLoader
  },
  data(){
    return {
      groupOptions: [],
      selectedAudience: 'all',
      message: {},
      showMessageModal: false,
      selectedRole: 'editor',
      collaboratorRoles: [
        {
          value: 'editor',
          label: 'Editor',
        },
        {
          value: 'admin',
          label: 'Admin',
        },
        {
          value: 'owner',
          label: 'Owner',
        },
      ],
      selectedRoles: [],
      loadingData: {},
      loadingConnections: false,
      showRequestModal: false,
    }
  },
  async mounted(){
    await this.$store.dispatch("profile/loadGroups");
    this.loadingConnections = true;
    await this.$store.dispatch("profile/getFriendsList");
    this.loadingConnections = false;
    this.audienceGroups.map((group)=>{
      let option = {
        label: group.group_name,
        value: group.id
      }
      this.groupOptions.push(option);
    })
  },
  computed: {
    audienceGroups(){
      return this.$store.getters['profile/getAudienceGroups'];
    },
    friends(){
      return this.$store.getters['profile/getFriends'];
    },
    connectionsInGroup(){
      return this.$store.getters['profile/getConnectionsInGroup'];
    },
  },
  methods: {
    async selectGroup(group){
      this.selectedAudience = group;
      this.loadingConnections = true;
      await this.$store.dispatch('profile/fetchGroupAudience', group);
      this.loadingConnections = false;
    },
    selectRole(user_email){
      let roleObj = this.selectedRoles.find((role, i) => {
      if (role.email === user_email) {
          this.selectedRoles[i] = { email: user_email, role: this.selectedRole };
          return true; 
        }
      })
      if(!roleObj){
         this.selectedRoles.unshift({
           email: user_email,
           role: this.selectedRole
         });
      }
    },
    async requestCollaboration(email, name, id){
      let collaborator_role;
      this.selectedRoles.find((roles)=>{
        if(roles.email===email){
          collaborator_role = roles.role;
          return true;
        }
      })
      let requestObj = {
        email: email,
        role: collaborator_role || 'editor',
        workspace: this.$store.getters['editor/getPublishedArticle'].workspace_id,
        name: name
      }
      this.showRequestModal = true;
      try {
        await this.$store.dispatch('editor/addCollaborator', requestObj);
        this.message = {
          title: "Collaborator has been added successfully",
          // desc: "Collaborator has been added successfully. Thanks for using Glocal",
          type: 'success'
        }
        this.showRequestModal = false;
        this.showMessageModal = true;
      }catch(err){
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: 'failure'
        }
        this.showRequestModal = false;
        this.showMessageModal = true;
      }
    },
  }
}
</script>
<style lang="scss">
@include reset-css;
.request-collaboration {
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    .ant-select-selection-item {
      font-size: 1.2rem;
      opacity: 0.8;
    }
  }
  .search {
    display: flex;
    align-items: center;
    border-bottom: 0.24rem solid #70707021;
    margin: 1.5rem 0;
    .icon {
      font-size: 2.2rem;
    }
    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 1.2rem 2rem;
      font-size: 1.6rem;
      font-family: $font-primary;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }
  .list-title {
    font-size: 1.9rem;
    font-family: $font-secondary-bold;
    margin-top: 3.6rem;
  }
  .user-list {
    height: 36rem;
    overflow-y: auto;
    padding-right: 2rem;
    .message{
      text-align: center;
      margin: 2rem auto;
    }
    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0;
      border-bottom: 0.24rem solid #7070700c;
      &__left {
        display: flex;
        align-items: center;
        .user-img {
          width: 4.7rem;
          img {
            width: 100%;
            border-radius: 100%;
          }
        }
        .user-name {
          margin-left: 1rem;
          h4 {
            font-size: 1.6rem;
            font-family: $font-primary-semibold;
            margin-bottom: 0;
          }
          p {
            font-size: 1.2rem;
            font-family: $font-primary;
            margin-bottom: 0;
            cursor: pointer;
          }
        }
      }
      &__right {
        display: flex;
        align-items: center;
        .user-request {
          height: 4rem;
          font-size: 1.3rem;
          border: 0.23rem solid $color-primary;
          color: $color-primary;
          padding: 0 2rem;
          font-family: $font-secondary-bold;
          background-color: transparent;
          cursor: pointer;
          transition: 0.2s;
        }
        .user-request:hover {
          color: $color-white;
          background-color: $color-primary;
          border-color: $color-white;
        }
      }
    }
  }
  .user-list::-webkit-scrollbar {
    width: 0.5rem;
  }

  .user-list::-webkit-scrollbar-track {
    background-color: #c8c8c87d;
    border-radius: 0.8rem;
  }

  .user-list::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }
}
.loader{
  text-align: center;
}
</style>
