<template>
  <div class="workspace-body">
    <the-header></the-header>
    <workspace-sub-header manage></workspace-sub-header>
    <router-view></router-view>
    <div class="add-button">
      <div class="light-house-btn">
        <i class="icon icon-lighthouse"></i>
      </div>
      <div class="mark-impact">
        <p>Mark as Impact Article</p>
        <a-switch v-model:checked="checked"/>
      </div>
      <workspace-tools></workspace-tools>
    </div>
  </div>
</template>

<script>
import TheHeader from "../components/layout/WorkspaceHeader.vue";
import WorkspaceSubHeader from "../components/WorkspaceComponents/WorkspaceSubHeader.vue";
import WorkspaceTools from "../components/WorkspaceComponents/AddButton.vue";

export default {
  components: {
    TheHeader,
    WorkspaceSubHeader,
    WorkspaceTools,
  },
};
</script>

<style lang="scss" scoped>
@include reset-css;

.workspace-body {
  padding: 3.5rem 4rem;
  padding-bottom: 0 !important;
}

.add-button {
  position: fixed;
  bottom: 9rem;
  right: 12rem;

  .light-house-btn {
    background-color: $color-primary;
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 2.5rem;
    border-radius: 100%;

    .icon {
      color: $color-white;
      font-size: 2.5rem;
      cursor: pointer;
      transition: 0.2s transform;
      transform: rotate(0deg);
    }
  }

  .light-house-btn::before {
    position: absolute;
    content: "";
    background-color: red;
    width: 1.1rem;
    height: 1.1rem;
    z-index: 22222;
    border-radius: 100%;
    top: 0;
    right: 0.7rem;
  }

  .mark-impact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    padding: 1.3rem 2rem;
    margin-bottom: 8.3rem;
    position: absolute;
    box-shadow: 0 3px 44px #00000030;
    right: 7rem;
    bottom: 0;
    width: 40.2rem;

    p {
      font-size: 1.4rem;
      font-family: $font-secondary-bold;
    }

    .ant-switch-checked {
      background-color: $color-primary;
    }
  }
}
</style>
